.blog-image, .card-display .card {
  box-shadow: 0 0 8px 1px rgba(166, 166, 166, 0.38);
  -webkit-box-shadow: 0 0 8px 1px rgba(166, 166, 166, 0.38);
  -moz-box-shadow: 0 0 8px 1px rrgba(71, 59, 59, 0.38);
}

/* Kudos to Mel Shields for this menu! https://codepen.io/shieldsma91 */
.mobile-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10px;
  left: -5px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.mobile-menu .menu-label {
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.6rem;
  font-weight: 500;
  margin: -20px 0 0;
}

.mobile-menu input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.mobile-menu span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #333333;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.mobile-menu span:first-child {
  transform-origin: 0% 0%;
}

.mobile-menu span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.mobile-menu input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #fafafa;
}

.mobile-menu input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.mobile-menu input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

.mobile-menu-links {
  opacity: 0;
  visibility: hidden;
  background-color: #333333;
  position: absolute;
  width: 450px;
  height: 900px;
  box-shadow: 0 0 10px #dddddd;
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  list-style-type: none;
  margin: -50px 0 0 -400px;
  padding: 120px;
  padding-top: 75px;
}
.mobile-menu-links li {
  padding: 20px 0;
  transition-delay: 0.4s;
}
.mobile-menu-links li a {
  color: #fafafa;
  text-decoration: none;
}
.mobile-menu-links li a .fab {
  margin-right: 10px;
}

.mobile-menu input:checked ~ ul {
  visibility: visible;
  transition-delay: 250ms;
  transform: none;
  transition: opacity 0.5s ease;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .mobile-menu-links {
    display: none;
  }

  .mobile-menu {
    display: none;
  }
}
* {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #3D778A;
}

body {
  background-color: #fafafa;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  color: #333333;
  font-family: "Lora", Georgia, Times, Times New Roman, serif;
  font-size: 18px;
  line-height: 1.8rem;
}

.wrapper {
  background-color: #fafafa;
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
}

.container {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 90px;
  width: 100%;
}
.container.blog-image-exists {
  padding-top: 0;
}
.container main.about-me-content {
  order: 1;
}
.container main.about-me-images {
  order: 2;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 20px 20px;
}
header .brand a {
  align-items: center;
  color: #333333;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
header .brand a img.logo {
  height: 40px;
  margin-right: 10px;
  width: auto;
}
header .brand a .site-title {
  flex-grow: 1;
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
header .brand a .site-title span,
header .brand a .site-title strong {
  display: block;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 1.2rem;
  text-transform: uppercase;
  text-align: center;
}
header .brand a .site-title strong {
  font-weight: 700;
  font-size: 1.2rem;
}
header nav ul.horizontal-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
header nav ul.horizontal-menu li {
  display: inline;
  padding: 0 8px;
}
header nav ul.horizontal-menu li a {
  color: #333333;
  text-decoration: none;
}
header nav ul.horizontal-menu li a.active, header nav ul.horizontal-menu li a:hover {
  border-bottom: 2px solid #4FA0BA;
}
header nav ul.horizontal-menu li a.active {
  font-weight: 700;
}
header nav ul.horizontal-menu li a .fab {
  font-size: 0.9rem;
}
header nav ul.horizontal-menu li a .fab + span + span {
  display: none;
}
header nav .mobile-menu {
  margin-left: 20px;
}
header nav .mobile-menu .mobile-menu-button {
  cursor: pointer;
}
header nav .mobile-menu .mobile-menu-button > span {
  color: #333333;
  display: block;
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
}
header nav .mobile-menu .mobile-menu-button > span.h-line {
  background-color: #333333;
  height: 4px;
  line-height: 4px;
  width: 38px;
}
header nav .mobile-menu .mobile-menu-button > span.h-line:last-child {
  margin-top: 4px;
}

.blog-image-header {
  padding-top: 90px;
}
.blog-image-header img {
  width: 100%;
}

.hide {
  display: none;
}

.resize-50 {
  width: 50% !important;
}

.portfolio-extract {
  border-top: 1px solid #dddddd;
  width: 100%;
}
.portfolio-extract:last-child {
  border-bottom: 1px solid #dddddd;
}
.portfolio-extract h2 {
  margin-top: 10px;
}
.portfolio-extract h2 span {
  font-size: 0.8rem;
  font-weight: 500;
}
.portfolio-extract h2 span a {
  color: #3D778A;
}
.portfolio-extract .portfolio-logo {
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  width: 100%;
}
.portfolio-extract .portfolio-text {
  font-size: 1rem;
  line-height: 1.6rem;
  margin: 0;
}
.portfolio-extract .portfolio-text p:last-of-type {
  font-weight: 700;
  margin-bottom: 5px;
}
.portfolio-extract .portfolio-text ul {
  margin-top: 0;
}

.image-collage img {
  border: 10px solid #fafafa;
  width: 90%;
}

.logo-collection {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0 20px;
  padding: 40px;
}
.logo-collection img {
  margin: 20px;
}

.img-skew {
  display: none;
  -webkit-backface-visibility: hidden;
  -webkit-transform: skew(-10deg, 0);
  overflow: hidden;
  width: 300px;
  height: 100%;
  position: relative;
  float: right;
  margin-left: 60px;
  z-index: 1;
}
.img-skew img {
  -webkit-transform: skew(10deg, 0);
  position: relative;
  left: -90px;
}

blockquote {
  color: #474747;
  font-style: italic;
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin-left: 20px;
  margin-right: 20px;
}

.skip-link {
  display: none;
}

.reading-time {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 20px 0 0;
}

.pull-right {
  display: block;
  width: 100%;
}

.card-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-display .card {
  background-color: #ffffff;
  font-size: 0.8rem;
  line-height: 1rem;
  margin-top: 20px;
  text-align: center;
  width: 300px;
}
.card-display .card.padding {
  padding: 20px 10px 10px;
}
.card-display .card img {
  width: 100%;
}
.card-display .card img.no-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.card-display .card figcaption {
  color: #717171;
  font-size: 0.8rem;
  font-style: italic;
  line-height: 1rem;
  padding-bottom: 5px;
  text-align: center;
}

.blog-image {
  border: 1px solid #dddddd;
  border-radius: 8px;
  width: 100%;
}
.blog-image.no-border {
  border: none;
}
.blog-image.clipped {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.no-resize {
  width: auto;
}

.credits {
  color: #717171;
  font-size: 0.9rem;
  font-style: italic;
  line-height: 1.2rem;
  margin: 20px 0;
}
.credits .fa-info-circle {
  color: #717171;
}

figure {
  margin: 0;
}
figure pre,
figure code {
  font-family: monospace, monospace;
}
figure pre {
  background-color: #333333;
  border-radius: 8px;
  color: #fafafa;
  overflow: auto;
  resize: horizontal;
  max-width: 300px;
  padding: 0.8rem 0;
}
figure pre code {
  color: #fafafa;
  display: block;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0 0.5rem;
  word-wrap: normal;
}

h2 {
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.post-link {
  color: #3D778A;
  font-family: "Lora", Georgia, Times, Times New Roman, serif;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0;
}

.post-more-link {
  color: #3D778A;
  font-size: 0.9rem;
}

.post-tag {
  background-color: #3D778A;
  border-radius: 4px;
  color: #fafafa;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  padding: 2px 8px;
  text-transform: uppercase;
  text-decoration: none;
}

.post-time {
  color: #717171;
  font-size: 0.8em;
}

.post-summary h1 {
  margin-bottom: 20px;
}
.post-summary p {
  margin: 0;
}
.post-summary p:first-of-type {
  margin-top: 20px 0;
}
.post-summary p:last-of-type {
  margin: 0 0 20px;
}

.slash-left {
  display: block;
}

p {
  margin: 1rem 0;
}

main {
  display: flex;
  flex-direction: column;
  order: 2;
}
main.full-width {
  display: block;
}
main section {
  padding: 10px 30px;
}
main section ul {
  padding-left: 30px;
}
main section article h1 {
  font-size: 1.8rem;
  line-height: 2rem;
  margin: 0;
}
main section article p {
  margin-top: 0;
}
main section article h2 {
  margin: 30px 0 10px;
}
main section article h2 a {
  color: #3D778A;
}
main section article a {
  color: #3D778A;
}
main section article a .fas {
  font-size: 0.6rem;
}
main h1 {
  font-size: 1.8rem;
  line-height: 2rem;
  margin: 20px 0 0;
}

aside {
  order: 1;
  padding: 10px 30px;
}
aside .ali-profile-image {
  margin-top: 40px;
}
aside.blog-links {
  border-left: 1px solid #dddddd;
  order: 3;
  margin-top: 20px;
  padding: 0 30px;
}
aside.blog-links ul {
  padding-left: 20px;
}
aside.blog-links ul li {
  line-height: 1.4rem;
  padding-left: 10px;
  padding-bottom: 10px;
}
aside.blog-links ul li a {
  background-color: #fafafa;
  color: #3D778A;
  font-size: 1.1rem;
  font-weight: 600;
}
aside h2,
aside h3 {
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

footer {
  color: #717171;
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.1rem;
  margin: 20px 0;
  text-align: center;
}

@media only screen and (min-width: 667px) {
  body {
    padding: 0;
  }

  .slash-left {
    display: inline;
  }
  .slash-left::before {
    content: "/ ";
  }

  .portfolio-extract {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
  }
  .portfolio-extract h2 {
    margin-top: 10px;
  }
  .portfolio-extract .portfolio-logo {
    padding: 20px;
    text-align: center;
    min-width: 250px;
    width: auto;
  }
  .portfolio-extract .portfolio-text {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 0;
  }

  .image-collage img {
    margin-top: -80px;
    width: auto;
  }
  .image-collage img:nth-child(2) {
    border-left: none;
    border-right: 20px solid #fafafa;
  }
  .image-collage img:first-child {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) {
  header {
    padding-top: 0;
  }
  header nav .mobile-menu {
    display: none;
  }
  header nav ul.horizontal-menu {
    display: block;
  }

  header,
footer {
    margin: 30px auto;
  }

  main section {
    border-bottom: 1px solid #dddddd;
  }

  .pull-right {
    display: inline-block;
    float: right;
    margin-left: 20px;
    margin-right: 0;
    width: auto;
  }

  aside .ali-profile-image {
    margin-top: 0;
  }
  aside.blog-links {
    border-left: none;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  figure pre {
    max-width: 600px;
  }
  figure pre code {
    font-size: 1rem;
    padding: 0 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .img-skew {
    display: block;
  }

  body {
    font-size: 16px;
    line-height: 1.6rem;
  }

  .blog-image-header {
    padding-top: 0;
  }

  .card-display {
    margin-top: 20px;
  }
  .card-display .card {
    margin: 0 10px 10px;
    padding: 10px;
    width: 42%;
  }
  .card-display .card figcaption {
    width: 100%;
  }
  .card-display .card img {
    width: 100%;
  }
  .card-display .card img.logo {
    width: auto;
  }
  .card-display .card p {
    margin: 5px 0;
  }

  .container {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin: 0 auto;
    max-width: 980px;
    padding-top: 120px;
  }
  .container.blog-image-exists {
    padding-top: 0;
  }

  header {
    background-color: #fafafa;
    width: 980px;
    padding-top: 40px;
    position: fixed;
    left: 50%;
    margin-top: 0;
    transform: translateX(-50%);
    z-index: 10;
  }
  header .brand a img.logo {
    height: 30px;
  }
  header .brand a .site-title span::before {
    color: #4FA0BA;
    content: "/ ";
  }
  header .brand a .site-title span,
header .brand a .site-title strong {
    display: inline-block;
    line-height: inherit;
    text-align: left;
  }
  header .brand a .site-title span {
    margin-left: 5px;
  }
  header .brand a .site-title strong {
    margin-right: 5px;
  }

  main {
    margin: 20px 0 0;
    order: 1;
    width: 100%;
  }
  main section {
    border-bottom: none;
    display: inline-block;
    padding: 20px;
  }
  main section > h1 {
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-top: 0;
  }
  main section h2 {
    font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 30px 0 10px;
  }

  blockquote {
    margin-left: 40px;
    margin-right: 40px;
  }

  aside {
    display: inline-block;
    order: 2;
    margin-left: 30px;
    margin-right: 30px;
    padding: 30px;
    width: 350px;
  }
  aside.blog-links {
    border-left: 1px solid #dddddd;
    display: inline-block;
    width: 350px;
  }
  aside.blog-links ul li {
    line-height: 1.2rem;
  }
  aside.blog-links ul li a {
    font-size: 0.9rem;
  }

  footer {
    margin-left: 50px;
    text-align: left;
    margin: 0 auto;
    max-width: 980px;
  }
}
h1 {
  font-family: "Montserrat", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

/* Kudos to Nick Lewis who wrote the original animation! https://codepen.io/nickylew */
.ali-profile-image {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;
  margin: auto;
  background-color: #4fa0ba;
  background-image: url("../img/ali-blue.jpg");
  background-size: 350px auto;
  background-position: top;
  border-radius: 20% 60% 60% 20%/20% 20% 60% 60%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: morphing 10s infinite;
  overflow: hidden;
  cursor: pointer;
}

.ali-profile-image:hover {
  animation-play-state: paused;
  background-image: url("../img/ali-bw.jpg");
}

@media only screen and (min-width: 768px) {
  .ali-profile-image {
    background-position: center;
    height: 300px;
  }
}
@media only screen and (min-width: 1440px) {
  .ali-profile-image {
    background-size: 350px auto;
  }
}
@-moz-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
@-webkit-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
@-o-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
